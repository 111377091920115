// import logo from './technology.png'

const NavBar = () => {
    return (
        <header class="text-gray-600 body-font">
            <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a href='/' class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                    {/* <img src={logo} class="h-4" alt=''/> */}
                    <span class="text-xl hover:text-2xl font-bold text-red-500 hover:text-red-700">DETORQ</span>
                </a>
                <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
                    <a href='/design' class="mr-5 hover:text-red-700">Design</a>
                    <a href='/manufactring' class="mr-5 hover:text-red-700">Manufacturing</a>
                    <a href='/analysis' class="mr-5 hover:text-red-700">Analysis</a>
                    <a href='/simulation' class="mr-5 hover:text-red-700">Simulation</a>
                </nav>
            </div>
        </header>
    )
}

export default NavBar