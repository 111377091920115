let icon = (<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-red-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
    <path d="M22 4L12 14.01l-3-3"></path>
</svg>);

const Services = () => {
    return (
        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
                <div class="text-center mb-20">
                    <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">Services We Provide</h1>
                    <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">We can handle your all industrial designing work from concept to manufacturing drawings. In Future we will also provide in house manufacturing. Soon Detorq will be the only stop to get your whole products and we hardly working for achieveing the same.</p>
                </div>
                <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">Conceptulized CAD Designs</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">Engineering Design</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">FEA/CFD/CAE</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">Drafting & Detailing</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">Plastic Mold Design</span>
                        </div>
                    </div>
                    <div class="p-2 sm:w-1/2 w-full">
                        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
                            {icon}
                            <span class="title-font font-medium">Simulation for Mechanisms</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services