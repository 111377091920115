import './App.css';
import NavBar from './components/navBar';
import Hero from './components/Hero';
import Services from './components/services';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Hero/>
      <Services/>
      <Footer/>
    </div>
  );
}

export default App;
